import React from "react";

import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Breadcrumbs from "../components/Breadcrumbs";

const ParentService = ({ pageContext, data }) => {
  const { allStrapiService } = data;
  console.log("allStrapiService", allStrapiService);
  return (
    <Layout bg="gray" header="light">
      <section className="bg-white">
        {/* {!isPreviewFromAdmin ? (
                <GatsbyImage
                  image={heroImage ? heroImage : placeHolderImageData}
                  className="w-full mx-auto max-h-60 md:shadow-xl md:max-h-80 md:rounded-lg overflow-hidden"
                />
              ) : null}
           */}
        <div className="max-w-7xl mx-auto  px-4 py-5 sm:px-6 sm:py-4 lg:px-8">
          <div className="w-full max-w-7xl mx-auto py-3 md:py-6">
            <Breadcrumbs
              className="justify-start text-base mb-6"
              links={[
                { to: "/", label: "Home" },
                {
                  to: `/${pageContext.edge.node.Slug}`,
                  label: pageContext.edge.node.Name + " Services",
                },
              ]}
            />

            {allStrapiService.edges.map(
              ({ node: { id, Name, Slug, service_area_ranks } }) => (
                <div key={id}>
                  <h2 className="text-2xl font-bold">{Name}</h2>
                  {service_area_ranks.map(({ id, RankedList }) => (
                    <div className="grid md:grid-cols-5 my-2" key={id}>
                      {RankedList.map((node) => (
                        <div key={node.id} className="mb-2">
                          <h3 className="font-bold">{node.business.Name}</h3>
                          {node.business.Address && (
                            <div className="my-2">
                              <div className="block">
                                {node.business.Address}
                              </div>
                              <div>
                                {node.business.City}, {node.business.State}{" "}
                                {node.business.ZIP}
                              </div>
                            </div>
                          )}
                          <div>
                            <a href={`tel:${node.business.phone}`}>
                              {node.business.phone}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ParentService;

export const ParentServiceQuery = graphql`
  query ParentServiceQuery($Slugs: [String]!) {
    allStrapiService(filter: { Slug: { in: $Slugs } }) {
      edges {
        node {
          id
          Name
          Slug
          service_area_ranks {
            id
            RankedList {
              id
              business {
                id
                Name
                phone
                Address
                City
                State
                ZIP
              }
            }
          }
        }
      }
    }
  }
`;
